import React, { useState, ImgHTMLAttributes } from 'react';

interface CoinProps extends ImgHTMLAttributes<HTMLImageElement> {
  name: string;
  width?: number;
  height?: number;
}

const Coin: React.FC<CoinProps> = ({ name, width = 32, height, ...props }) => {
  const [error, setError] = useState(false);
  const coin = name.toLocaleLowerCase();

  const iconPath = `assets/coins/${coin}.svg`;
  const defaultIcon = `assets/coins/default.svg`;

  return (
    <img
      src={!error ? iconPath : defaultIcon}
      alt={name}
      width={width}
      height={height}
      onError={() => setError(true)}
      {...props}
    />
  );
};

export default Coin;
