import { useState } from "react";
import { TabItem } from "../types/types";

type Props = {
    tabs: TabItem[]
    defaultTab?: TabItem
}

export const useTabs = ({ tabs, defaultTab }: Props) => {
  const [selectedTab, setSelectedTab] = useState<TabItem>(defaultTab ||tabs[0]);

  const handleTabChange = (tab: TabItem) => {
    setSelectedTab(tab);
  };

  return {
    selectedTab,
    handleTabChange,
  };
};