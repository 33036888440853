import { createApi } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./../../constants";
import { axiosBaseQuery } from "./baseQuery";
import { AddExchangeKeyInput } from "../../types/add-exchange-key.input";

interface Account {}

interface Balance {}

interface Currency {
  name: string,
  id: number
}

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  tagTypes: [
    "Account",
    "Balance",
    "ExchangeKey",
    "PaperTradeBalance",
    "Currency",
  ],
  endpoints: (builder) => ({
    getAccount: builder.query<any, void>({
      query: () => ({ url: "/account", method: "GET" }),
      providesTags: ["Account"],
    }),
    getAllBalance: builder.query<any[], void>({
      query: () => ({ url: "/account/allbalance", method: "GET" }),
      providesTags: ["Balance"],
    }),
    addExchangeKey: builder.mutation<AddExchangeKeyInput, any>({
      query: (data: AddExchangeKeyInput) => ({
        url: "/account/exchangekey",
        method: "POST",
        data,
      }),
      invalidatesTags: [
        { type: "ExchangeKey", id: "LIST" },
        { type: "Account" },
      ],
    }),
    addPaperTradeBalance: builder.mutation<any, any>({
      query: (data) => ({
        url: "/account/papertrading/balance/add",
        method: "POST",
        data,
      }),
      invalidatesTags: ["Account"],
    }),
    addCurrency: builder.mutation<any, any>({
      query: (data) => ({
        url: "/account/currency/associate",
        method: "POST",
        data,
      }),
      invalidatesTags: ["Account"],
    }),
    removePaperTradeBalance: builder.mutation<any, number>({
      query: (id) => ({
        url: `/account/papertrading/balance/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: "PaperTradeBalance", id },
        "Account"
      ],
    }),
    removeExchangeKey: builder.mutation<any, number>({
      query: (id) => ({
        url: `/account/exchangekey/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ExchangeKey", "Account"],
    }),
    confirmEmail: builder.query<any, any>({
      query: (token) => ({
        url: `/account/confirm/${token}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetAccountQuery,
  useGetAllBalanceQuery,
  useAddExchangeKeyMutation,
  useRemoveExchangeKeyMutation,
  useAddPaperTradeBalanceMutation,
  useRemovePaperTradeBalanceMutation,
  useAddCurrencyMutation,
  useConfirmEmailQuery,
} = accountApi;
