import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        stroke="#F9F9FF"
        strokeLinecap="round"
        strokeWidth={1.2}
        d="M19.071 20.174a9.97 9.97 0 0 0 2.93-7.07 9.969 9.969 0 0 0-2.93-7.072M4.929 5.507A9.969 9.969 0 0 0 2 12.577a9.969 9.969 0 0 0 2.929 7.072M7.55 8.154a6.978 6.978 0 0 0-2.05 4.95c0 1.932.784 3.682 2.05 4.95M16.45 17.527a6.978 6.978 0 0 0 2.05-4.95 6.978 6.978 0 0 0-2.05-4.95"
      />
      <path
        stroke="#F9F9FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M12 22.578v-5M12 2.578v5"
      />
      <path
        fill="#F9F9FF"
        d="M12 14.078a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .578h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent
