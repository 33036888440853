import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#EFEFFF"
      d="M21 19.328h-.75v-15a.75.75 0 0 0-.75-.75h-5.25a.75.75 0 0 0-.75.75v3.75H9a.75.75 0 0 0-.75.75v3.75H4.5a.75.75 0 0 0-.75.75v6H3a.75.75 0 0 0 0 1.5h18a.75.75 0 1 0 0-1.5Zm-6-14.25h3.75v14.25H15V5.078Zm-5.25 4.5h3.75v9.75H9.75v-9.75Zm-4.5 4.5h3v5.25h-3v-5.25Z"
    />
  </svg>
);
export default SvgComponent
