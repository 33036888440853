import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Drawer, DrawerHeader } from "./muiStyles";
import logo from "../../assets/images/logo.svg";
import { NavLink, useLocation } from "react-router-dom";
import { menuItems } from "./items";
import styles from "./layout.module.scss";

interface SidebarProps {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  open,
  handleDrawerOpen,
  handleDrawerClose,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);

  const handleSubMenuClick = (path: string) => {
    setOpenSubMenu((prev) => (prev === path ? null : path));
  };

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img className={styles.logo} src={logo} alt="Botico" />
        </DrawerHeader>
        <List className={styles.menu}>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem disablePadding className={styles.menuItem}>
                {item.path ? (
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      isActive ? `${styles.active}` : ""
                    }
                    style={{
                      pointerEvents: item.disabled ? "none" : "auto",
                      cursor: item.disabled ? "not-allowed" : "pointer",
                      color: item.disabled ? "#6C767E" : "",
                    }}
                  >
                    <ListItemButton
                      sx={[
                        { minHeight: 48, px: 2.5 },
                        open
                          ? { justifyContent: "initial" }
                          : { justifyContent: "center" },
                      ]}
                    >
                      <ListItemIcon
                        sx={[
                          { minWidth: 0, justifyContent: "center" },
                          open ? { mr: 3 } : { mr: "auto" },
                        ]}
                        className={`py-1 px-1.5 rounded-md ${
                          location.pathname.startsWith(item.path)
                            ? "bg-secondary-500"
                            : ""
                        }`}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={t(item.name)}
                        sx={[open ? { opacity: 1 } : { opacity: 0 }]}
                      />
                    </ListItemButton>
                  </NavLink>
                ) : (
                  <div
                    onClick={() => handleSubMenuClick(item.name)}
                    className={styles.noPathMenuItem}
                  >
                    <ListItemButton
                      sx={[
                        { minHeight: 48, px: 2.5 },
                        open
                          ? { justifyContent: "initial" }
                          : { justifyContent: "center" },
                      ]}
                    >
                      <ListItemIcon
                        sx={[
                          { minWidth: 0, justifyContent: "center" },
                          open ? { mr: 3 } : { mr: "auto" },
                        ]}
                        className={`py-1 px-1.5 rounded-md ${
                          location.pathname === item.path
                            ? "bg-secondary-500"
                            : ""
                        }`}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={t(item.name)}
                        sx={[open ? { opacity: 1 } : { opacity: 0 }]}
                      />
                      {open ? (
                        openSubMenu === item.name ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      ) : null}
                    </ListItemButton>
                  </div>
                )}
              </ListItem>
              {item.subMenu && (
                <Collapse
                  in={openSubMenu === item.name}
                  timeout="auto"
                  unmountOnExit
                >
                  <List disablePadding>
                    {item.subMenu.map((subItem, subIndex) => (
                      <ListItem
                        key={subIndex}
                        disablePadding
                        className={styles.subMenuItem}
                      >
                        <NavLink
                          to={subItem.path}
                          className={({ isActive }) => {
                            return `${
                              location.pathname === subItem.path
                                ? styles.active
                                : ""
                            } w-full`;
                          }}
                        >
                          <ListItemButton sx={{ pl: 10 }}>
                            <ListItemText primary={t(subItem.name)} />
                          </ListItemButton>
                        </NavLink>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
        <div className={styles.navigate}>
          <IconButton
            onClick={handleDrawerOpen}
            sx={[open && { display: "none" }]}
          >
            <LastPageIcon />
          </IconButton>
          <IconButton
            onClick={handleDrawerClose}
            sx={[!open && { display: "none" }]}
          >
            <FirstPageIcon />
          </IconButton>
        </div>
      </Drawer>
    </>
  );
};

export default Sidebar;
