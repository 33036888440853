import { useState } from "react";
import history from "../../history";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { TextField, IconButton, InputAdornment, InputLabel } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./login.module.scss";
import { useLoginMutation } from "../../redux/api/authApi";
import AppButton from "../../components/Button/AppButton";
import { validationLoginSchema } from "./validationSchema";

interface IFormInput {
  email: string;
  password: string;
}

interface ErrorWithStatus {
  status: number;
}

const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const [login, { isLoading, error }] = useLoginMutation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formError, setFormError] = useState<string | null>(null);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    resolver: yupResolver(validationLoginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = async ({ email, password }) => {
    try {
      const { access_token } = await login({ email, password }).unwrap();
      localStorage.setItem("access_token", access_token);
      navigate("/");
    } catch (err) {
      const error = err as ErrorWithStatus;
      if (error && "status" in error) {
        const status = error.status;
        if (status === 401) {
          setFormError("Invalid email or password");
        } else {
          setFormError("An error occurred. Please try again later.");
        }
      } else {
        setFormError("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <div className={styles.loginFormContainer}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.loginForm}>
        <h2 className={styles.title}>{t("pages.login.form.title")}</h2>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <div className="mb-3 flex flex-col w-full">
              <InputLabel className="mb-1 text-white">
                {t("pages.login.form.email")}
              </InputLabel>
              <TextField
                {...field}
                style={{ border: formError ? "1px solid red" : "none" }}
                className="wfull"
                autoComplete="off"
                error={!!errors.email || !!formError}
                helperText={
                  errors.email ? errors.email.message : formError || ""
                }
                slotProps={{
                  htmlInput: {
                    className: "py-3 rounded-md",
                  },
                }}
              />
            </div>
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <div className="mb-3 flex flex-col w-full">
              <InputLabel className="mb-1 text-white">
                {t("pages.login.form.password")}
              </InputLabel>
              <TextField
                {...field}
                className="wfull"
                style={{ border: formError ? "1px solid red" : "none" }}
                type={showPassword ? "text" : "password"}
                error={!!errors.password || !!formError}
                helperText={
                  errors.password ? errors.password.message : formError || ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: "#A8B5BE" }}
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                slotProps={{
                  htmlInput: {
                    className: "py-3 rounded-md",
                  },
                }}
              />
            </div>
          )}
        />
        <AppButton type="submit" variant="primary" fullWidth>
          {t("pages.login.form.button")}
        </AppButton>

        <div className={styles.registerText}>
          Don’t have an account?{" "}
          <span
            className={styles.registerLink}
            onClick={() => {
              history.push("/registration");
            }}
          >
            Sign up
          </span>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
