import { useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import history from "../../history";
import AccountMenu from "./AccountMenu";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import logo from "../../assets/images/b-logo.svg";
import { TickerTape } from "react-ts-tradingview-widgets";
import { coinList } from "./coinList";
import { Toolbar } from "@mui/material";
import { AppBar } from "../../components/Layout/muiStyles";
import AppButton from "../Button/AppButton";

import styles from "./header.module.scss";

interface HeaderProps {
  open: boolean;
}

const Header: React.FC<HeaderProps> = ({ open }) => {
  const { t } = useTranslation();
  const [accountAnchor, setAccountAnchor] = useState<null | HTMLElement>(null);
  const isAccountMenuOpen = Boolean(accountAnchor);

  const handleOpenAccountMenu = (event: MouseEvent<HTMLElement>) => {
    setAccountAnchor(event.currentTarget);
  };

  const handleCloseAccountMenu = () => {
    setAccountAnchor(null);
  };

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <div className={styles.header}>
          {!open && (
            <div className={styles.logo}>
              <img src={logo} alt="botico" />
            </div>
          )}

          <div className="container flex">
            <div className={styles.ticker}>
              <TickerTape colorTheme="dark" symbols={coinList} isTransparent />
            </div>

            <div className={styles.navigation}>
              <AppButton
                variant="primary"
                startIcon={<AddOutlinedIcon />}
                onClick={() => history.push("/bot-lab")}
              >
                {t("common.create-bot")}
              </AppButton>
              <IconButton onClick={handleOpenAccountMenu}>
                <AccountCircleIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </Toolbar>
      <AccountMenu
        open={isAccountMenuOpen}
        anchorEl={accountAnchor}
        onClose={handleCloseAccountMenu}
      />
    </AppBar>
  );
};

export default Header;
