import React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import AppButton from "../Button/AppButton";
import { useAppDispatch } from "../../redux/store";
import { closeModal } from "../Modal/modal.slice";

type Props = {
  content?: string;
  onClose?: () => void;
  onConfirm?: () => void;
};

const ConfirmationModal = ({ onClose, onConfirm, content }: Props) => {
  const dispatch = useAppDispatch();
  return (
    <>
      <DialogContent className="bg-primary-800">{content}</DialogContent>
      <DialogActions className="bg-primary-800 p-4">
        <AppButton
          variant="ghost"
          size="small"
          onClick={() => {
            onClose?.();
            dispatch(closeModal());
          }}
        >
          Cancel
        </AppButton>
        <AppButton
          variant="danger"
          size="small"
          onClick={() => {
            onClose?.();
            dispatch(closeModal());
            onConfirm?.();
          }}
        >
          Delete
        </AppButton>
      </DialogActions>
    </>
  );
};

export default ConfirmationModal;
