import { TextField, IconButton, Chip } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import {
  useAddBQCurrencyMutation,
  useGetBQCurrencyQuery,
  useRemoveBQCurrencyMutation,
} from "../../redux/api/currencyApi";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AppButton from "../../components/Button/AppButton";
import Coin from "../../components/Coin/Coin";

const BQPair: React.FC = () => {
  const { data } = useGetBQCurrencyQuery();
  const [addBQCurrency] = useAddBQCurrencyMutation();
  const [removeBQCurrency] = useRemoveBQCurrencyMutation();

  const { control, handleSubmit } = useForm({
    defaultValues: { name: "" },
  });

  const onSubmit = async (value) => {
    await addBQCurrency(value);
  };

  const handleRemove = async (id: number) => {
    await removeBQCurrency(id);
  };

  return (
    <div className="p-4">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mb-4 flex items-center space-x-2"
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              variant="outlined"
              className="w-64"
              onChange={(event) => {
                field.onChange(event);
              }}
              autoComplete="off"
            />
          )}
        />
        <AppButton size="small" type="submit" variant="primary">
          Add
        </AppButton>
      </form>

      <div className="mt-8 flex flex-wrap">
        {data?.map((currency) => (
          <div key={currency.id} className="flex p-2 items-center">
            <Chip
              key={currency.name}
              label={currency.name.toUpperCase()}
              onDelete={() => handleRemove(currency.id)}
              className="bg-transparent"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BQPair;
